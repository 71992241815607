import request from '@/utils/request'


/**
 * 易支付接口
 * @param {} data 
 * @returns 
 */
export function yiZhiFuPay(data) {
    return request({
        url: '/api/yzfPay/pay',
        method: 'post',
        data
    })
}


/**
 * 支付宝当面付
 * @param {} data 
 * @returns 
 */
export function aliFacePay(data) {
    return request({
        url: '/api/aliFace/pay',
        method: 'post',
        data
    })
}